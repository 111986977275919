/* Use only in combination with HostListener decorator. Execute descriptor when 'Enter' button is pressed.  */
export function onEnterKeyDown(isResetPaginationSkipped?: boolean): any {
  return (target: any, property: any, descriptor: TypedPropertyDescriptor<any>) => {
    const originMethod = descriptor.value;

    descriptor.value = function (eventCode: string): any {
      if (eventCode && eventCode !== 'Enter') {
        return;
      } else if (eventCode === 'Enter') {
        if (isResetPaginationSkipped) {
          try {
            this.resetPagination();
          } catch {
            console.error(`To reset the pagination, add resetPagination method to ${this.constructor.name}`);
          }
        }
      }

      return originMethod.apply(this);
    };

    return descriptor;
  };
}
